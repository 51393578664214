import React, { useEffect, useState } from 'react';
import * as S from './TrustPilotPluginV1.styles.js';

const TrustPilotPluginV1 = props => {
  const {
    templateId,
    businessUnitId,
    height,
    width,
    theme,
    tags,
    stars,
    locale,
    languages,
    isInnerSlide
  } = props;

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!loaded && isInnerSlide) {
      const reloadWidgets = () => {
        var element = document.getElementsByClassName('trustpilot-widget');
        for (var i = 0; i < element.length; i++) {
          window.Trustpilot.loadFromElement(element[i]);
        }
      };

      if (!window.Trustpilot) {
        const script = document.createElement('script');
        script.src =
          'https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js';
        script.async = true;
        script.onload = () => {
          reloadWidgets();
        };
        document.body.appendChild(script);
      } else {
        reloadWidgets();
      }
    }
    return () => setLoaded(true);
  }, [loaded, isInnerSlide]);

  return (
    <S.Container h={height}>
      <div
        className="trustpilot-widget"
        data-locale={locale || 'en-GB'}
        data-template-id={templateId}
        data-businessunit-id={businessUnitId}
        data-style-height={height}
        data-style-width={width}
        data-theme={theme || 'light'}
        data-tags={tags}
        data-stars={stars}
        data-review-languages={languages || 'en'}
      />
    </S.Container>
  );
};

export default TrustPilotPluginV1;
